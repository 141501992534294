export var PaymentMethodFormType;
(function (PaymentMethodFormType) {
    PaymentMethodFormType["AddPaymentMethod"] = "ADD_PAYMENT_METHOD";
    PaymentMethodFormType["EditPaymentMethod"] = "EDIT_PAYMENT_METHOD";
    PaymentMethodFormType["ChangeSubscriptionPlan"] = "CHANGE_SUBSCRIPTION_PLAN";
})(PaymentMethodFormType || (PaymentMethodFormType = {}));
// TODO: remove
export var ChangeSubscriptionStrategy;
(function (ChangeSubscriptionStrategy) {
    ChangeSubscriptionStrategy["Downgrade"] = "DOWNGRADE";
    ChangeSubscriptionStrategy["Upgrade"] = "UPGRADE";
})(ChangeSubscriptionStrategy || (ChangeSubscriptionStrategy = {}));
