export var AuthenticationMethod;
(function (AuthenticationMethod) {
    AuthenticationMethod["EMAIL"] = "EMAIL";
    AuthenticationMethod["PHONE_NUMBER"] = "PHONE_NUMBER";
})(AuthenticationMethod || (AuthenticationMethod = {}));
export var AuthenticationMode;
(function (AuthenticationMode) {
    AuthenticationMode["SIGN_IN"] = "SIGN_IN";
    AuthenticationMode["SIGN_UP"] = "SIGN_UP";
    AuthenticationMode["RESET_PASSWORD"] = "RESET_PASSWORD";
})(AuthenticationMode || (AuthenticationMode = {}));
