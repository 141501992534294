import jwtDecode from 'jwt-decode';
import { LocalStorageRefreshTokenKey, LocalStorageTokenKey } from '@app/constants/localStorageKeys';
import { AuthenticationMode, } from '@entities/auth/model';
import getUnauthorizedState from '@entities/auth/lib/getUnauthorizedState';
export const logoutReducer = (state) => {
    localStorage.removeItem(LocalStorageTokenKey);
    localStorage.removeItem(LocalStorageRefreshTokenKey);
    return Object.assign(Object.assign({}, state), { auth: undefined });
};
export const updateTokenReducer = (state, { payload: { accessToken, refreshToken } }) => {
    try {
        const authData = jwtDecode(accessToken);
        if (!authData.subject.id) {
            throw new Error('invalid token'); // TODO: handle properly
        }
        state.authMode = AuthenticationMode.SIGN_IN;
        state.authStep = null;
        localStorage.setItem(LocalStorageTokenKey, accessToken);
        localStorage.setItem(LocalStorageRefreshTokenKey, refreshToken);
        state.auth = {
            accessToken,
            refreshToken,
            userId: authData.subject.id,
        };
        return state;
    }
    catch (ex) {
        return getUnauthorizedState();
    }
};
