import { PaymentMethodFormType } from '../model';
import { CHANGE_SUBSCRIPTION_INIT_FIELDS, PAYMENT_METHOD_EDIT_FIELDS, PAYMENT_METHOD_INIT_FIELDS } from './constants';
const INIT_VALUES = {
    clientProfile: undefined,
    traderProfile: undefined,
};
// eslint-disable-next-line import/prefer-default-export
export const defineProfiles = (profiles) => (profiles === null || profiles === void 0 ? void 0 : profiles.reduce((acc, profile) => {
    if (profile.type === 'CLIENT') {
        acc.clientProfile = profile;
    }
    else if (profile.type === 'TRADER') {
        acc.traderProfile = profile;
    }
    return acc;
}, INIT_VALUES)) || INIT_VALUES;
const getPaymentFormFields = ({ type, plan, currentPlan, currentSubscription, defaultStep, }) => {
    switch (type) {
        case PaymentMethodFormType.AddPaymentMethod:
            return Object.assign({}, PAYMENT_METHOD_INIT_FIELDS);
        case PaymentMethodFormType.EditPaymentMethod:
            return Object.assign({}, PAYMENT_METHOD_EDIT_FIELDS);
        case PaymentMethodFormType.ChangeSubscriptionPlan:
            return Object.assign(Object.assign({}, CHANGE_SUBSCRIPTION_INIT_FIELDS), { currentPlan, newPlan: plan, currentSubscription,
                defaultStep });
        default:
            return Object.assign({}, PAYMENT_METHOD_INIT_FIELDS);
    }
};
export { getPaymentFormFields, };
