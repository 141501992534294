import jwtDecode from 'jwt-decode';
import { AuthenticationMode } from '@entities/auth/model';
import { LocalStorageRefreshTokenKey, LocalStorageTokenKey } from '@app/constants/localStorageKeys';
import getUnauthorizedState from '@entities/auth/lib/getUnauthorizedState';
const getInitialState = () => {
    try {
        const accessToken = localStorage.getItem(LocalStorageTokenKey);
        const refreshToken = localStorage.getItem(LocalStorageRefreshTokenKey);
        if (!accessToken || !refreshToken) {
            throw new Error('no token'); // TODO: handle properly
        }
        const authData = jwtDecode(accessToken);
        if (!authData.subject.id) {
            throw new Error('invalid token'); // TODO: handle properly
        }
        return {
            auth: {
                accessToken,
                refreshToken,
                userId: authData.subject.id,
            },
            authMethod: null,
            authMode: AuthenticationMode.SIGN_IN,
            authStep: 0,
        };
    }
    catch (ex) {
        return getUnauthorizedState();
    }
};
export default getInitialState;
